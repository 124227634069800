import React, { useEffect, useState } from "react"
import Layout from "../components/Layout/Layout"
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'
import Button from "../components/Button/Button";
import Helmet from "react-helmet"
import SEO from "../components/SEO/SEO";

function BlogTemplate(props: any) {

    const post = props.pageContext.article

    return (
        <Layout style={{margin: 0, padding: 0}}>
            <Helmet>
                <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"/>
                <script src="https://cdn.jsdelivr.net/npm/sharer.js@latest/sharer.min.js"/>
            </Helmet>
            <SEO description={post.description}
                 title={post.title}
                 url={`https://blog.retrobie.com/${post.slug}`}
                 image={`https://cms.retrobie.com/${post.image.formats.large?.url}`}/>
            <div className={"blog-parent"}>
                <header>
                    <h1>{post.title}</h1>
                    <img src={`https://cms.retrobie.com${post.image.formats.large?.url}`} alt={"featured image"}/>
                    <div style={{justifyContent: "center", display: "flex", gap: "1rem"}}>
                        <p>
                            Written by: {post.author.name}
                        </p>
                        <p>
                            Read time: {post.timeToRead} min read
                        </p>
                    </div>
                </header>
                <div className="m-auto">
                    <div style={{maxWidth: "800px"}}>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                            {post.content}
                        </ReactMarkdown>
                    </div>
                </div>
                <div className={"newsletter"}>

                    <div id="mc_embed_signup">
                        <div className={"images"}>
                            <img src="https://cdn.jsdelivr.net/gh/Bradleykingz/icons/image_3.svg"
                                 alt={"black and brown heel"}/>
                        </div>

                        <form
                            action="https://store.us15.list-manage.com/subscribe/post?u=6ec31ce43b70efd818395b2ae&amp;id=159a07cdbd"
                            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                            className="validate" target="_blank" noValidate>
                            <div id="mc_embed_signup_scroll">
                                <h3>Subscribe to our newsletter</h3>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <div style={{maxWidth: "600px", textAlign: "left"}}>
                                        <p style={{color: "#353535"}}>
                                            You seem like someone with great taste. Stick around and discover
                                            amazing
                                            shoes and offers.
                                            Subscribers get access to exclusive offers and be the first to know when
                                            new shoes are in stock!
                                            ta </p>
                                        <p style={{color: "#353535"}}>
                                            No annoying emails. We'll only reach out when we <em>really</em> think
                                            you'll like something.
                                        </p>
                                    </div>

                                </div>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}>
                                    <div className="cta--input" style={{width: "100%", display: "flex"}}>
                                        <input placeholder="you@gmail.com" type="email"
                                               defaultValue=""
                                               name="EMAIL"
                                               style={{flex: 1}}
                                               className="required email" id="mce-EMAIL"/>
                                        <Button
                                            isColor={"primary"}
                                            style={{minWidth: "150px"}} type="submit"
                                            defaultValue="Subscribe" name="subscribe"
                                            id="mc-embedded-subscribe" className="button">
                                            Get Started
                                        </Button>
                                    </div>
                                </div>
                                <div id="mce-responses" className="clear">
                                    <div className="response" id="mce-error-response" style={{display: "none"}}/>
                                    <div className="response" id="mce-success-response" style={{display: "none"}}/>
                                </div>
                                <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                    <input type="text" name="b_6ec31ce43b70efd818395b2ae_159a07cdbd" tabIndex={-1}
                                           defaultValue=""/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default BlogTemplate;
